.article-screen .container {
    display: flex;
    margin: 0;
    text-align: left;
}

.article-screen .main-article {
    padding: 0 80px;
}

.article-screen .article-headline-box {
    border-bottom: 6px solid orange;
    height: 500px;
    padding: 40px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
    font-size: 50px;
}

.article-screen .article-headline {
    font-size: 54px;
    font-weight: bold;
    margin: auto 0;
    color: aliceblue;
    width: 800px;
}

.article-screen .article-text-headline {
    font-size: 22px;
    font-weight: bolder;
}


.article-screen .article-text {
    font-size: 18px;
    text-align: left;
}