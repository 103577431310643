.screen-training .rectangles {
    display: flex;
    gap: 36px;
    margin-top:36px ;
}

.screen-training .rectangles .rectangle {
    width: 33.3%;
    height: 300px;
    border: 1px solid black;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.screen-training .rectangles .rectangle.yellow {
    background-color: rgb(255, 255, 108);
}

.screen-training .rectangles .rectangle.red {
    background-color: tomato;
}

.screen-training .rectangles .rectangle.blue {
    background-color: rgb(9, 218, 255);
}

.screen-training .task-2 {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 36px;
}

.screen-training .task-2 .circle {
    width: 250px;
    height: 250px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 36px;
}

.screen-training .task-2 .circle.beige {
    background-color: rgb(215, 215, 158);
}

.screen-training .task-2 .circle.tomato {
    background-color: tomato;
}

.screen-training .task-3 img {
    width: 200px;
    height: 200px;
}

.screen-training .task-3 {
    display: flex;
}

.screen-training .task-3 .feature {
    width: 33.3%;
    text-align: center ;
}

.screen-training .task-4 ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    gap: 25px;
}

.screen-training .task-4 ul li {
    border-bottom: 0.5px solid black;
    display: flex;
    list-style-type: none;
    padding: 10px;
    width: 50%;
}

.screen-training .task-4 ul li img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 20px;
    transition: all linear 150ms;
}

.screen-training .task-4 ul li:hover img {
    transform: scale(1.06);
}

.screen-training .task-4 ul li .li-content {
    align-items: right;
    flex: 1;
}

.screen-training .task-5 {
    align-items: center;
}

.screen-training .task-5 .cover {
    background-size: cover;
    min-height: 130vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.screen-training .task-5 .cover h2 {
    text-align: center;
    font-size: 60px;
    color: white;
    margin-left: 130px;
    margin-bottom: 50px;
    letter-spacing: 1px;
}

.screen-training .task-5 .cover button {
    text-align: center;
    padding: 10px 10px;
    width: 200px;
    background-color: rgb(202, 30, 0);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    font-size: 18px;
    font-weight: bold;
    transition: all linear 200ms;
    top: 60%;
    left: 30%;
    margin-top: 50px;
    margin-left: 180px;
    animation: rotate 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
  
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

@keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

.screen-training .task-5 .cover button:hover {
    background-color: rgb(158, 158, 158);
    transform: scale(1.1);
    color: white;
}
