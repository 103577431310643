.calculator-datafill {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.calculator-datafill .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.calculator-datafill .form-group:nth-last-child(1) {
    border-bottom: 1px solid black;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.calculator-datafill h3 {
    margin-top: 0;
    font-size: 15px;
}