.dashboard-screen {
    display: flex;
    background-color: azure;
    height: 100vh;
}

.dashboard-screen .dashboard-menu {
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 10px;
    padding-top: 40px;
    align-items: center;
    background-color: #1e9beb;
}

.dashboard-screen .dashboard-menu button {
    padding: 10px 20px;
    width: 80%;
    border-radius: 15px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #ffc500;
    border: none;
}

.dashboard-screen .dashboard-menu button:hover {
    background-color: whitesmoke;
    cursor: pointer;
    color: blues;
}


.dashboard-screen .main-content {
    width: 100%;
}

.dashboard-screen .main-content .users-headline {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.dashboard-screen .main-content .users-headline .headline {
    margin: 15px 30px 15px 30px;
    font-size: 40px;
    letter-spacing: 1px;
}

.dashboard-screen .main-content .users-headline button {
    margin: 20px;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 16px;
    background-color: #ffc500;
    border: none;
}

.dashboard-screen .main-content .users-headline button:hover {
    background-color: #1e9beb;
    cursor: pointer;
    color: white;
}

.dashboard-screen .main-content .user-filters {
    display: flex;
    margin: 30px;
    margin-bottom: 0;
}

.dashboard-screen .main-content .user-filters button {
    width: 150px;
    margin: 0;
    padding:0;
    border-radius: 20px;
    padding: 6px 12px;
    border: none;
    background-color: #0066a5;
    color: white;
    font-weight: bold;
}

.dashboard-screen .main-content .user-filters .form-group {
    display: flex;
    flex-direction: column;
    margin: 20px 20px 20px 0;
    justify-content: flex-end;
}
 
.dashboard-screen .main-content .user-filters .form-group label {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}

.dashboard-screen .main-content .user-filters .form-group input {
    cursor: pointer;
    border-radius: 10px;
    padding: 5px 10px;
    border: 0.5px solid black;
    background-color: white;
}


.dashboard-screen .dashboard {
    display: flex;
    justify-content: center;
}

.dashboard-screen .dashboard .demo-table {
    width: 100%;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 15px;
}

.dashboard-screen .dashboard .demo-table thead {
    background-color: #0066a5;
}

.demo-table thead tr th {
    background-color: #ffc500;
    color: rgb(59, 59, 59);
    font-weight: bold;
    padding: 6px 12px;
}

.dashboard-screen .dashboard .demo-table tr {
    text-align: center;
}

.dashboard-screen .dashboard .demo-table td {
    background-color: #1e9beb;
}

.dashboard-screen .dashboard .demo-table td:nth-child(2) {
    background-color: #0066a5;
}


.dashboard-screen .dashboard .demo-table tbody tr td:first-child {
    text-align: center;
}

.dashboard-screen .dashboard .demo-table tr td:last-child {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #0066a5;
}

.dashboard-screen .dashboard .demo-table tr td button {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    background-color: #ffc500;
    border: none;
}

.dashboard-screen .dashboard .demo-table tr td button:hover {
    background-color: #1e9beb;
    cursor: pointer;
    color: white;
}

