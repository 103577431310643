.shipping-data {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shipping-data .checkout-form {
    display: flex;
    flex-direction: column;
}

.shipping-data .title {
    text-align: center;
}

.shipping-data .form-row {
    padding: 0 12px;
    font-size: 15px;
    font-weight: bolder;
    width: 50%;
    margin-bottom: 15px;

}

.shipping-data .form-row input {
    margin: 6px 0 0;
    padding: 8px;
    border: 1px solid orange;
    border-radius: 10px;
    font-weight: bolder;
    width: 100%;
    box-sizing: border-box;
}

.shipping-data .form-row select {
    margin: 0;
}

.shipping-data select.shipping-country {
    padding: 10px 15px;
}
