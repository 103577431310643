.product-management-screen {
    padding: 20px;
    min-height: 100vh;
}

.product-management-screen .title {
    text-align: center;
    font-size: 36px;
    letter-spacing: 1px;
    color: #1e70a6;
    margin-bottom: 40px;
}

.product-management-screen .product-content-container {
    display: flex;
}

.product-management-screen .product-content-container .product-block-left {
    width: 50%;
    background-color: #51e6ff;
    padding: 40px 60px;
    flex: 1;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    display: flex;
    flex-direction:column;
    align-items: center;
    transition: all linear 150ms;
}

.product-management-screen .product-content-container .product-block-left:hover {
    transform: scale(1.008);
}

.product-management-screen .product-content-container .product-block-right {
    width: 50%;
    background-color: tomato;
    padding: 40px 60px;
    flex: 1;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
    transition: all linear 150ms;
    flex-direction: column;
}

.product-management-screen .product-content-container .product-block-right .headline {
    background-color: #51e6ff;
    color: white;
}


.product-management-screen .product-content-container .product-block-right .image-wrapper {
    padding: 20px;
    border: 3px dashed white;
    margin: 0 0 16px;
    border-radius: 7px;
}

.product-management-screen .product-content-container .product-block-right .uploaded-image {
    width: 100%;
    object-fit: cover;
    height: 225px;
    border-radius: 7px;
}

.product-management-screen .product-content-container .product-block-right input {
    background-color: #51e6ff;
    width: 50%;
}


.product-management-screen .product-content-container .product-block-right:hover {
    transform: scale(1.008);
}

.product-management-screen .product-content-container .headline {
    text-align: center;
    margin: 0 auto 20px;
    font-size: 18px;
    letter-spacing: 1px;
    background-color: white;
    border-radius: 7px;
    padding: 5px;
    width: 50%;
    color: #51e6ff;
}

.product-management-screen .product-content-container .data-fill {
    width: 100%;
}

.product-management-screen .product-content-container .product-block-left .form-group {
    margin-top: 10px;
    margin-bottom: 18px;
    flex-direction: column;
    display: flex;
}

.product-management-screen .product-content-container .product-block-left .form-group label {
    font-size: 16px;
    margin: 0 0 8px 8px;
    color: black;
    font-weight: 400;
    letter-spacing: 0.8px;
}

.product-management-screen .product-content-container .product-block-left .form-group select {
    padding: 5px 15px;
    font-size: 16px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-sizing: border-box;
}

.product-management-screen .product-content-container .product-block-left .form-group input {
    padding: 5px 15px;
    font-size: 16px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-sizing: border-box;
}

.product-management-screen .product-content-container .product-block-left .form-row-group  {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.product-management-screen .product-content-container .product-block-left .form-row-group .form-group {
    width: 50%;
}

.product-management-screen .product-content-container .product-block-left .button {
    width: 50%;
    padding: 9px;
    font-size: 16px;
    background-color: tomato;
    border: none;
    border-radius: 7px;
    margin-top: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all linear 160ms;
    color: white;
}

.product-management-screen .product-content-container .product-block-left .button:hover {
    background-color: white;
    transform: scale(1.05);
}

.product-management-screen .product-content-container .product-block-right .button {
    width: 50%;
    padding: 9px;
    font-size: 16px;
    background-color: #51e6ff;
    border: none;
    border-radius: 7px;
    margin-top: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all linear 160ms;
}

.product-management-screen .product-content-container .product-block-right .button:hover {
    background-color: white;
    transform: scale(1.05);
}



