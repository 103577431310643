.competition-screen {
    padding: 60px 0;
    height: 100%;
    background-position: center;
    background-size: cover;
    transition: all linear 0.25s;
}

.competition-screen.won-screen {

    
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
} 

@keyframes changeBg {
    0% {
        background-size: 100%;
    }
    50% {
        background-size: 140%;
    }
    100% {
        background-size: 100%;
    }
}


.competition-screen .container {
    max-width: 1000px;
    margin: auto;
}

.competition-screen .title {
    font-size: 60px;
    color: rgb(255, 217, 0);
    text-align: center;
    letter-spacing: 1px;
    margin-top: 0;
    animation-name: titleFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 1;
}

@keyframes titleFadeIn {
    0% {
        top: 80px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

.competition-screen .headline {
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: normal;
    letter-spacing: 1px;
}

.competition-screen .game-row {
    display: flex;
    justify-content: center;
    margin-top: 0;
}

.competition-screen .game-element {
    height: 100px;
    width: 100px;
    border-radius: 8px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
}

.competition-screen .game-element:hover {
    transition: all 0.5s;
    transform: scale(1.1);
}

.competition-screen .won-state {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 0;
}

.competition-screen .won-state .title {
    text-align: center;
    padding: 8px;
    border-radius: 20px;
    box-shadow: 9px 9px 33px 9px #e9f943;
    font-size: 60px;
    font-weight: bold;
    margin-top: 120px;
    margin-bottom: 0;
    color: yellow;
    letter-spacing: 1px;
    animation-name: fadeIn,grow;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-iteration-count: 1, infinite;
    animation-delay: 1s;
    opacity: 0;
}


@keyframes grow {
    0% {
        transform: scale(1);
        rotate: +3deg;
    }
    50% {
        transform: scale(1.4);
        rotate: -3deg;
    }
    100% {
        transform: scale(1);
        rotate: +3deg;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.competition-screen .won-state .headline {
    font-size: 30px;
    color: rgb(57 28 31);
    letter-spacing: 1px;
    margin: auto;
    padding: 8px 24px;
    font-weight: bold;
    box-shadow: 1px 1px 90px 10px purple;
    background-color: rgba(255, 255, 255, 0.703);
    border-radius: 20px;
    animation-name: winnerFadeIn;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes winnerFadeIn {
    0% {
        opacity: 0;
        left: 80px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

.competition-screen .headline-won-state small {
    display: block;
    font-size: 34px;
    color: purple;
    margin-bottom: 8px;
    padding-top: 8px;
}

.competition-screen .headline-won-state {
    font-size: 20px;
    margin-bottom: 16px;
    max-width: 550px;
}