.features {
    min-height: 80vh;
    padding: 120px 0;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    background-color: rgb(0, 50, 119);
    background-image: url(/public/img/horizon.jpg);
}

.features .container {
    display: flex;
}

.features .img-wrap {
    padding: 12px;
}

.features ul {
    text-align: center;
    margin: 40px 16px;
    color: black;
    display: flex;
}

.features ul li {
    display: flex;
    margin: 0 60px;
    width: 33.33%;
    animation-name: featureBallsFadeIn;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 1;
    flex-direction: column;
    position: relative;
}


.features ul .image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    list-style-type: none;
    background-position: center;
    background-size: cover;
    padding: 0;
    position: relative;
    border: none;
    transition: all 1s;
}

@keyframes featureBallsFadeIn {
    0% {
        opacity:0;
        bottom: 0;        
    }

    100% {
        opacity:1;
        bottom: 80px;
    }
}

.features ul li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: .5s;
  transform: scale(.9);
}

.features ul .img-wrap:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px aqua;
}

.features ul .img-wrap:hover .image {
    cursor: pointer;
    transform: rotateY(360deg);
    transition: all 0.25s;
    color: rgb(0, 128, 128);
    box-shadow: 0 0 5px aqua;
    text-shadow: 0 0 1px #10ff18;
}

.features ul li:hover:nth-child(2) {
    rotate: +2deg;
}

.features .title {
    font-size: 24px;
    margin-top: 38px;
    font-weight: bold;
    letter-spacing: 1px;
    color: white;
}

.features .description {
    font-size: 21px;
    letter-spacing: 1px;
    color: white;
}