.component-cart-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.component-cart-content .title {
    text-align: center;
    font-size: 28px;
}

.component-cart-content .checkout-form {
    max-width: max-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.component-cart-content .checkout-form p {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.component-cart-content ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.component-cart-content ul li .cart-item-image {
    height: 200px;
    width: 288px;
    
}

.component-cart-content ul li {
    display: flex;
    border-radius: 10px;
    margin: 25px;
    flex-direction: column;
    width: fit-content;
    background-position: center;
    background-size: cover;
    border: 0.5px solid orange;
    box-shadow: 6px 6px orange;
    cursor: pointer;
}

.component-cart-content ul li:hover {
    transform: scale(1.04);
    transition: all 150ms;
    box-shadow: 7px 7px 0px 0px rgb(114 154 237);
    border: 0.5px solid #678ad4;
}

.component-cart-content ul li img {
    object-fit: cover;
    width: 35%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.component-cart-content ul li span {
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin: 14px ;
    letter-spacing: 1px;
    color: rgb(0, 50, 119);
    display: flex;
    justify-content: center;
    text-align: center;
}

.component-cart-content ul li .cart-item-price {
    margin-bottom: 10px;
}

.component-cart-content .step-navigation {
    width: 300px;
}