.main-navigation {
    padding: 5px 20px;
    background-size: cover;
    display: flex;

}

.main-navigation-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0 0 0 32px;
    width: 100%;
    align-items: center;
}

.main-navigation-links .cart {
    margin-left: auto;
    position: relative;
}

.main-navigation .cart-dropdown {
   width: 350px;
   position: absolute;
   right: 0;
   top: 35px;
   background: white;
   border-radius: 12px;
   display: none;
   z-index: 100;
}

.main-navigation .cart-dropdown-menu {
    padding:0;
    margin:0;
    list-style-type: none;
}

.main-navigation .cart-item-name {
    width: 55%;
}

.main-navigation .cart-item-quantity {
    width: 15%;
}

.main-navigation .cart-item-price {
    width: 30%;
    font-weight: bold;

}

.main-navigation-links .cart img {
    height: 40px;
}

.main-navigation .cart-dropdown-menu li {
    padding: 4px 12px;
    display: flex;
    color: black;
    cursor: pointer;
}

.main-navigation .cart-dropdown-menu li:nth-child(even) {
    background-color: white;
    color: rgb(4, 0, 85);
    cursor: pointer;
}

.main-navigation .checkout-button {
    padding: 12px 16px;
    justify-content: center;
    background: transparent;
    cursor: pointer;
}

.main-navigation .checkout-button button {
    border-radius: 25px;
    background-color: #ffbb00;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    font-size: 13px;
    font-weight: bold;
    color: #4e4e4e;
    cursor: pointer;
}

.main-navigation .checkout-button button:hover {
    transform: scale(1.02);
    transition: all linear 300ms;
    background-image: url(/public/img/checkoutbg.jpg);
    background-position: center;
    background-size: cover;
    color: white;
}

.main-navigation .main-navigation-links li a {
    padding: 8px 12px;
    display: inline-flex;
    text-decoration: none;
    color:black;
    font-size: 13px;
    font-weight: bold;
    align-items: center;
    letter-spacing: 1px;
}

.main-navigation .cart-dropdown:hover {
    display: block;
}

.main-navigation .cart  a:hover + .cart-dropdown {
    display: block;
}

.main-navigation .main-navigation-links li a:hover {
    color: red;
    transform: scale(1.1);
    transition: all linear 150ms;
}