.component-products-search {
    display: flex;
    gap: 3px;
}

.component-products-search .filters {
    width: 25%;
    min-height: 100vh;
    background-color: #c4ffff;
}

.component-products-search .filters .title {
    text-align: center;
    color: rgb(37 73 123);
    background: rgb(38, 137, 167);
    margin: 0;
    padding: 24px;
    background-position: center;
    background-size: 65%;
    font-size: 26px;
    margin-bottom: 20px;
    border-bottom: 4px solid #ffbb00;
}

.component-products-search .filters .title:hover {
    box-shadow: 0px 0px 4px 0px aqua;
    cursor: pointer;
}

.component-products-search .form-group {
    margin: 0 24px;
    padding: 0 0 16px;
    display: flex;
    flex-direction: column;
}

.component-products-search .form-control-label {
    font-size: 16px;
    display: block;
    margin: 0 0 12px;
    color: rgb(37 73 123);
    font-weight: bold;
    letter-spacing: 1px;
}

.component-products-search .form-control {
    box-sizing: border-box;
    width: 100%;
    padding: 4px 12px;
    font-size: 14px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    color: rgb(37 73 123);
}

.component-products-search .form-control:hover {
    background-color: #ffbb00;
}

.component-products-search .filter-button {
    width: 100%;
    border-radius: 25px;
    background-color: #ffbb00;
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: rgb(37 73 123);
    cursor: pointer;
}

.component-products-search .filter-button:hover {
    box-shadow: 1px 1px 10px 1px #ffbb00;
    transform: scale(1.05);
    transition: all 300ms;
    color: white;
    cursor: pointer;
}

.component-products-search .radio-button {
    margin: 0 0 10px;
    align-items: center;
    display: flex;
}

.component-products-search .radio-button span {
    color:rgb(37 73 123);
    font-weight: bold;
    font-size: 14px;
    padding-top: 3px;
}

.component-products-search .radio-button-control {
    margin-right: 10px;
    height: 14px;
    width: 14px;
}

.component-products-search .filters .form-control-checkbox {
    display: flex;
    align-items: center;
    margin: 0 0 12px;
}

.component-products-search .filters .checkbox-control {
    width: 14px;
    height: 14px;
    margin-right: 10px;
}

.component-products-search .result-items {
    width: 75%;
    padding: 60px;
    background-color: white;
}

.component-products-search .result-item-list {
    margin: 0;
    padding: 0;
    list-style-type:none ;
}

.component-products-search .result-item-list .result-item {
    display: flex;
    background-color: rgb(57 186 255);
    border-radius: 10px;
    margin: 0 0 30px;
}

.component-products-search .result-item-list .result-item:hover {
    transform: scale(1.02);
    transition: all 200ms;
    box-shadow: 1px 1px 10px 1px rgb(38, 137, 167);
}

.component-products-search .result-item-list .result-item .result-item-media {
    object-fit: cover;
    width: 35%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-size: cover;
    background-position: center;
}

.component-products-search .result-item-list .result-item .result-item-content {
    width: 65%;
    padding: 32px 24px;
    align-items: left;
    flex: 1;
}

.component-products-search .result-item-list .result-item .result-item-name {
    font-size:28px;
    font-weight: bold;
    margin: 0;
    letter-spacing: 1px;
    color: rgb(0, 50, 119);
    padding-left:8px ;
    cursor: pointer;
    transition: all linear 100ms;
    border-style: none;

}

.component-products-search .result-item-list .result-item .result-item-name a {
    text-decoration: none;
    color: rgb(0, 50, 119);
}

.component-products-search .result-item-list .result-item .result-item-name:hover {
    transform: scale(1.02);
}

.component-products-search .result-item-list .result-item .result-item-headline {
    color: white;
    font-size: 15px;
    margin-bottom: 50px;
    padding-left:10px ;
}

.component-products-search .result-item-list .result-item .result-item-price {
    font-size: 19px;
    color: white;
    font-weight: bolder;
    margin: 0;
    padding-left:10px ;
}

.component-products-search .result-item-list .result-item .result-item-button {
    width: 150px;
    border-radius: 15px;
    background-color: #ffbb00;
    padding: 9px 12px;
    margin: 20px 0;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: rgb(37 73 123);
    cursor: pointer;
    letter-spacing: 0.5px;
}

.component-products-search .result-item-list .result-item .result-item-button:hover {
    box-shadow: 1px 1px 1px 1px #ecd038;;
    transform: scale(1.05);
    transition: all 200ms;
    color: white;
    cursor: pointer;
}
