.checkout-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.checkout-summary .form-row {
    margin: 0;
}

.checkout-summary .customer-data-summary h2 {
    font-size: 18px;
    width: 200px;
    padding: 2px 20px;
    border-radius: 9px;
    text-align: center;
    box-shadow: 3px 3px 2px 2px #729aed;
}

.checkout-summary .customer-data-summary .form-row-group {
    flex-direction: column;
}

.checkout-summary .checkout-form {
    width: 50%;
}

.checkout-summary .checkout-form-blocks {
    display: flex;
    justify-content: space-between;
}

.checkout-summary .checkout-form p {
    font-weight: normal;
}

.checkout-summary .shipping-data {
    margin: 0;
    padding: 0;
}

.checkout-summary .shipping-summary .form-row-group {
    display: block;
}

.checkout-summary .shipping-summary h2 {
    font-size: 18px;
    width: 200px;
    padding: 2px 20px;
    border-radius: 9px;
    text-align: center;
    box-shadow: 3px 3px 2px 2px #729aed;
}

