.home-work .container {
    margin: 80px;
    padding: 0;
}

.home-work .headline {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 1px;
    color: rgb(0, 166, 255);
}

.home-work .city-cards {
    display: flex;
    justify-content: center;
}

.home-work .cities {
    width: 230px;
    height: 260px;
    display: flex;
    align-items: center;
    list-style-type: none;
    justify-content: center;
    margin: 20px;
    flex-direction: column;
    animation-name: cardsFadeIn;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    opacity: 1;
}

.home-work .city-img {
    width: 230px;
    height: 260px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    padding: 0;
    border: none;
    transition: all 0.7s;
    rotate: +5deg;
}

.home-work .title {
    rotate: +4deg;
}

.home-work .description {
    font-size: 20px;
    margin-top: 0;
    rotate: +4deg;
}

.home-work .city-img:hover {
    transform: scale(1.22);
    cursor: pointer;
}

@keyframes cardsFadeIn {
    0% {
        opacity:0;
        bottom: 0;        
    }

    100% {
        opacity:1;
        bottom: 80px;
    }
}