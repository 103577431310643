.demo-table {
  margin: 32px 12px;
  border-collapse: collapse;
  width: 400px;
}

.demo-table thead tr th {
  background: #dab758;
  color: rgb(59, 59, 59);
  font-weight: bold;
  padding: 6px 12px;
}

.demo-table thead tr th:first-child {
  border-top-left-radius: 12px;
}

.demo-table thead tr th:last-child {
  border-top-right-radius: 12px;
}

.demo-table tbody tr td {
  background-color: #5f5f5f;
  color: rgb(255, 255, 255);
  padding: 6px 12px;
  border: 1px solid gray;
}

.demo-table tbody tr td:first-child {
  text-align: right;
  font-weight: bold;
}

.demo-table tbody tr:nth-child(even) td {
  background-color: #4e4e4e;
  color: rgb(255, 255, 255);
}

.container {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px;
}