.articles-screen .container {
    margin: 0;
}

.article-items .article-item {
    display: flex;
    align-items: center;
    margin-bottom: 80px;
}

.article-items .article-item.reverse {
    flex-direction: row-reverse;
}

.article-item .article-item-image {
    height: 80vh;
    width: 50%;
    object-fit: cover;
}

.article-item .article-item-content {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px;
}

.article-item.reverse .article-item-content {
    text-align: right;
}

.article-item .article-item-title {
    font-weight: bold;
    font-size: 36px;
    border-left: 6px solid aqua ;
    padding-left: 20px;
}

.article-item.reverse .article-item-title {
    border-left: 0;
    padding-left: 0;
    border-right: 6px solid aqua;
    padding-right: 20px;
}

.article-item .article-item-content .headline {
    font-size: 22px;
}

.article-item .article-item-content a {
    text-decoration: none;
}

.article-item .article-item-content button:hover {
    transform: scale(1.05);
    border: 1.5px solid #005a69;
    background: aqua;
    color:  #005a69 ;
}

.article-item .article-item-content button {
    font-weight: bold;
    border-radius: 25px;
    justify-content: center;
    padding: 12px 20px;
    margin-top: 37px;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    background: rgb(38, 137, 167);
    border: 1px solid aqua;
    color: white;
    transition: all linear 300ms;
    letter-spacing: 1px;

}



