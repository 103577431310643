.product-page {
    background-position: center;
    background-size: cover;
    min-height: 180vh;
    padding: 20px 0 80px 0;
}

.product-page .product-name {
    margin: 0;
    padding: 35px;
    font-style: italic;
    font-size: 43px;
    letter-spacing: 1px;
    text-align: center;
    color: white;
}

.product-page .product-image {
    width: 50%;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px ;
    align-items: center;
    display: flex;
    padding: 0 16px;
}

.product-page .product-image button {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: orange;
    color: white;
    border: none;
    cursor: pointer;
    transition: all linear 200ms;
}

.product-page .product-image button:hover {
    background-color: lightgreen;
}

.product-page .product-image button:last-child {
    margin-left: auto;
}

.product-page .product-main-content {
    display: flex;
    align-items: stretchs;
}

.product-page .product-information {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    border-top-right-radius:20px ;
    border-bottom-right-radius: 20px;
}

.product-page h2 {
    font-size: 30px;
    margin-bottom: 0;
    letter-spacing: 1px;
}

.product-page h3 {
    font-size: 24px;
    font-weight: lighter;
    letter-spacing: 1px;
}

.product-page .product-main-content p {
    text-align: center;
}

.product-page .product-description {
    font-family: sans-serif;
    letter-spacing: 1px;
    font-size: 20px;
}

.product-page .shipping-information {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 20px;
}

.product-page .cart-button {
    width: 180px;
    border-radius: 15px;
    background-color: #ffbb00;
    padding: 10px 10px;
    margin: 20px 0;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: rgb(37 73 123);
    cursor: pointer;
    letter-spacing: 0.5px;
}

.product-page .cart-button:hover {
    box-shadow: 1px 1px 1px 1px #ecd038;;
    transform: scale(1.05);
    transition: all 200ms;
    color: white;
    cursor: pointer;
}

.product-page .product-extra-content div {
    width: 20%;
    height: 200px;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    transition: all linear 150ms;
}

.product-page .product-extra-content div:hover {
    transform: scale(1.08);
}

.product-page .product-extra-content {
    display: flex;
    background: rgb(123 181 238 / 84%);
    margin-top: 20px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 21px;
    gap: 24px;
    padding: 24px;
}

.product-page .product-extra-content .shipping-description {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
}

.product-page .product-extra-content .shipping-description p {
    margin: 10px 40px;
}

.product-page .product-extra-content .shipping-description .shipping-price-title {
    margin-top: 45px;
    margin-bottom: 0;
}

.product-page .product-extra-content .shipping-description .shipping-price {
    font-weight: lighter;
}