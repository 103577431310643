.contact-screen {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
}

.contact-screen .container {
    padding: 0;
}

.contact-screen .title {
    margin: 0px;
    padding: 25px;
    font-size: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #0759af;
}

.contact-screen .contact-information-row {
    display: flex;  
}

.contact-screen .contact-information-row .contact-screen-description {
    width: 40%;
    padding: 0px;
    display: flex;
    align-items: center;
    animation-name: textFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;
    animation-delay: 0.2s;
    opacity: 0;
    transition: all linear 200ms;
}

@keyframes textFadeIn {
    0% {
        opacity: 0;
        left: 120px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

.contact-screen .contact-information-row .contact-screen-description:hover {
    transition: all 1s;
    transform: scale(1.1);
}


.contact-screen .contact-information-row .contact-screen-description .headline {
    font-size: 25px;
    letter-spacing: 1px;
    margin-left: 60px;
    padding: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
}



.contact-screen .contact-information-box {
    background-color: rgb(21 172 213);
    margin: 50px;
    padding: 20px;
    width: 40%;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    display: flex;
    color: white;
    border-radius: 20px;
    box-shadow: 1px 1px 30px 10px rgb(22 172 208);
    animation-name: boxFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;
    animation-delay: 0.2s;
    opacity: 0;
    transition: all linear 200ms;
}

@keyframes boxFadeIn {
    0% {
        opacity: 0;
        right: 120px;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}

.contact-screen .contact-information-box:hover {
    transition: all 0.8s;
    transform: scale(1.05);
    cursor: pointer;
}

.contact-screen .contact-information-box p {
    font-size: 25px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 30px 1px white;
    color: white;
    font-weight: 400;
    margin: 20px;
}

.contact-screen .contact-information-box p:hover {
    color: rgb(4, 0, 85);
}
