.competition-screen .game-element {
    height: 100px;
    width: 100px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 1px 1px 30px 10px rgb(22 172 208);
    cursor: pointer;
    background-size: cover;
    background-position: center;
    margin: 10px;
}

.competition-screen .game-element:hover {
    transition: all .15ms;
    transform: scale(1.1);
}

