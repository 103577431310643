.component-switcher {
    margin-top: 40px;
}

.component-switcher ul {
    display: flex;
    list-style-type: none;
    margin-bottom: 0;
}

.component-switcher ul button {
    font-size: 17px;
    letter-spacing: 0.5px;
    border: none;
    background-color: rgb(0, 175, 0);
    color: white;
    padding: 10px 24px;
    cursor: pointer;
}

.component-switcher ul button.active {
    background-color: white;
    color: green;
}

.component-switcher .tab-controls li:first-child button {
    border-top-left-radius: 10px;
}

.component-switcher .tab-controls li:last-child button {
    border-top-right-radius: 10px;
}

.component-switcher ul button:hover {
    background-color: white;
    transition: all linear 200ms;
    color: black;
}