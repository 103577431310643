.product-page article {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 15px;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    background-blend-mode: hue;
}

.product-page .description-box {
    padding: 40px;
    margin: 0;
}

.product-page .description-box p {
    padding: 3px;
    margin: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-family: sans-serif;
    color: rgb(0, 0, 0);
}

.product-page .description-box-title {
    text-align: center;
    color: orange;
    font-weight: bold;
    text-transform: uppercase;
}

.product-page .features-list {
    display: flex;
    gap: 20px;
    background:none;
    margin-top: 20px;
}

.product-page .feature {
    width: 33.3%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-page .feature .feature-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: green;
    background: white;
    padding: 8px 18px;
    border-radius: 16px;
    border-bottom: 3px solid #eee;
    box-shadow: 1px 1px 7px 1px #ddd; 
    display: inline-block;
}

.product-page .features-list .feature:nth-child(2) .feature-title {
    background-color: orange;
    color: white;
    box-shadow: 1px 1px 7px 1px orange ;
    border-bottom: 3px solid orange;
}

.product-page .features-list img {
    width: 33.3%;
    height: 120px;
    margin-top: 20px;
    object-fit: contain;
}

.product-page .feature .feature-description {
    text-align: center;
    padding: 10px;
    letter-spacing: 1px;
    font-weight: 600;
}

.product-page .feature .feature-description span {
    color: green;
}

