.cover-component {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
}

.cover-component .container {
    max-width: 1200px;
    padding: 120px 40px;
    margin: 0;
}

.cover-component-title {
    color: white;
    font-size: 40px;
    font-weight: bold;
    margin-left: 20px;
    opacity: 0;
    animation-name: titleFadeIn;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    position: relative;
}

.cover-component-title:hover {
    transform: scale(1.02s);
    transition: all 1s;
    rotate: +2deg;
    cursor: pointer;
}

@keyframes titleFadeIn {
    0% {
        opacity: 0;
        left: -300px;
    }
    100% {
        opacity: 1;
        left:0;
    }
}

.cover-component-headline {
    color: white;
    max-width: 600px;
    padding: 20px 0;
    margin-left: 70px;
    animation-name: headlineFadeIn;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    position: relative;
    animation-delay: 0.5s;
    opacity: 0;
    transition: all linear 200ms;
    text-align: center;
}

.cover-component-headline:hover {
    rotate: +2deg;
}

@keyframes headlineFadeIn {
    0% {
        opacity: 0;
        top: 80px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}


.cover-component-button {
    font-weight: bold;
    border-radius: 25px;
    justify-content: center;
    padding: 12px 40px;
    display: flex;
    font-size: 18px;
    background-color: aqua;
    margin: 30px 0 40px 300px;
    color: #005a69;
    border: none ;
}

.cover-component-button:hover {
    background-color: rgb(3, 188, 216);
    box-shadow: 1px 1px 30px 1px aqua;
    rotate: -2deg;
    transform: scale(1.1);
    transition: all 300ms;
    color: white;
    cursor: pointer;
}
