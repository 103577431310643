.customer-data {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.form-row {
    padding: 13px;
    font-size: 15px;
    font-weight: bolder;
    width: 50%;
}

.form-row-group {
    display: flex;
    justify-content: space-between;
}

.form-row input {
    margin: 0 10px;
    padding: 8px;
    border: 1px solid orange;
    border-radius: 10px;
    font-weight: bolder;
    width: 100%;
}

.form-row select {
    margin: 0 10px;
    padding: 8px;
    border: 1px solid orange;
    border-radius: 10px;
    font-weight: bolder;
}

.checkout-form {
    background: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 15px 15px 0px 4px #719aed;
    min-width: 500px;
}

.customer-data .form-control {
    display: block;
    margin: 14px 0 0;
    box-sizing: border-box;
}

.step-navigation {
    display: flex;
}

.step-navigation button {
    width: 100%;
    border-radius: 25px;
    background: orange;
    padding: 10px 20px;
    margin: 20px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: rgb(37 73 123);
    cursor: pointer;
}

.step-navigation button:hover {
    transform: scale(1.02);
    transition: all linear 200ms;
    background-image: url(/public/img/checkoutbg.jpg);
    background-position: center;
    background-size: cover;
}
