.checkout-screen {
    padding: 40px 0;
    min-height: 100vh;
    background-size: cover;
}

.checkout-screen .main-title {
    font-size: 32px;
    text-align: center;
    margin: 32px;
}
.checkout-screen .checkout-steps-tags {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid grey;
}

.checkout-screen .checkout-steps-tags li {
    margin: 10px 20px;
    text-align: center;
    width: 100px;
}

.checkout-screen .checkout-steps-tags .tag-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.checkout-screen .checkout-steps-tags .tag-circle.active {
    border: 2px solid orange;
    background-color: orange;
}

